exports.components = {
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-detail-page-tsx": () => import("./../../../src/templates/detail-page.tsx" /* webpackChunkName: "component---src-templates-detail-page-tsx" */),
  "component---src-templates-events-calendar-page-tsx": () => import("./../../../src/templates/events-calendar-page.tsx" /* webpackChunkName: "component---src-templates-events-calendar-page-tsx" */),
  "component---src-templates-expert-detail-page-tsx": () => import("./../../../src/templates/expert-detail-page.tsx" /* webpackChunkName: "component---src-templates-expert-detail-page-tsx" */),
  "component---src-templates-experts-landing-page-tsx": () => import("./../../../src/templates/experts-landing-page.tsx" /* webpackChunkName: "component---src-templates-experts-landing-page-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-news-detail-page-tsx": () => import("./../../../src/templates/news-detail-page.tsx" /* webpackChunkName: "component---src-templates-news-detail-page-tsx" */),
  "component---src-templates-news-landing-page-tsx": () => import("./../../../src/templates/news-landing-page.tsx" /* webpackChunkName: "component---src-templates-news-landing-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-protected-resources-page-tsx": () => import("./../../../src/templates/protected-resources-page.tsx" /* webpackChunkName: "component---src-templates-protected-resources-page-tsx" */),
  "component---src-templates-resources-page-tsx": () => import("./../../../src/templates/resources-page.tsx" /* webpackChunkName: "component---src-templates-resources-page-tsx" */),
  "component---src-templates-search-page-tsx": () => import("./../../../src/templates/search-page.tsx" /* webpackChunkName: "component---src-templates-search-page-tsx" */),
  "component---src-templates-training-calendar-page-tsx": () => import("./../../../src/templates/training-calendar-page.tsx" /* webpackChunkName: "component---src-templates-training-calendar-page-tsx" */),
  "component---src-templates-training-detail-page-tsx": () => import("./../../../src/templates/training-detail-page.tsx" /* webpackChunkName: "component---src-templates-training-detail-page-tsx" */)
}


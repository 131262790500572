/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import React from 'react'
import { ThemeProvider } from './src/context/theme-context'
import './src/styles/fontello/css/fontello.css'
import './src/styles/global.css'

export const onRouteUpdate = () => {
  document.body.classList.remove('no-scroll')
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer')
  }
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
